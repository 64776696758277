import axios from 'axios'

const client = axios.create({
  baseURL: 'https://emhvwvpkqc.execute-api.ap-southeast-1.amazonaws.com/prod/sionac',
  json: true
})

export default {
  async execute (method, resource, data) {
    return client({
      method,
      url: resource,
      data
    }).then(req => {
      let cmd = ''
      if (data.cmd) cmd = data.cmd
      // if (process.env.NODE_ENV === 'development') {
      // console.log('method: ' + method.toUpperCase() + ' (' + cmd + ')')
      // console.log('url: ' + this.baseURL)
      console.log('request data: ')
      console.log(JSON.stringify(data))
      console.log('response: ')
      console.log(JSON.stringify(req.data))
      // }
      return req.data
    })
  },
  getUser (idToken) {
    return this.execute('post', '/', {'cmd': 'GET_USER', 'idToken': idToken})
  },

  listUser () {
    return this.execute('post', '/', {'cmd': 'LIST_USER', 'idToken': localStorage.getItem('idToken')})
  },
  listBahanPA (option) {
    return this.execute('post', '/', {'cmd': 'LIST_BAHAN_PA', 'idToken': localStorage.getItem('idToken'), 'option': option})
  },
  listBahanPsk (option) {
    return this.execute('post', '/', {'cmd': 'LIST_BAHAN_PSK', 'idToken': localStorage.getItem('idToken'), 'option': option})
  },
  listLink (userId) {
    return this.execute('post', '/', {'cmd': 'LIST_LINK', 'idToken': localStorage.getItem('idToken'), 'userId': userId})
  },
  listSignup () {
    return this.execute('post', '/', {'cmd': 'LIST_SIGNUP', 'idToken': localStorage.getItem('idToken')})
  },
  listWorker () {
    return this.execute('post', '/', {'cmd': 'LIST_WORKER', 'idToken': localStorage.getItem('idToken')})
  },
  listPeriod () {
    return this.execute('post', '/', {'cmd': 'LIST_PERIOD', 'idToken': localStorage.getItem('idToken')})
  },
  listJournalPA (userId, option) {
    return this.execute('post', '/', {'cmd': 'LIST_JOURNAL_PA', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'option': option})
  },
  listCity (userId) {
    return this.execute('post', '/', {'cmd': 'LIST_CITY', 'idToken': localStorage.getItem('idToken'), 'userId': userId})
  },

  //listMember & listModule return list for multiselect
  listMember () {
    return this.execute('post', '/', {'cmd': 'LIST_MEMBER', 'idToken': localStorage.getItem('idToken')})
  },
  listModule () {
    return this.execute('post', '/', {'cmd': 'LIST_MODULE', 'idToken': localStorage.getItem('idToken')})
  },

  signup (name, email, mobile, campus, major, yearIn, city, mentor) {
    return this.execute('post', '/', {'cmd': 'SIGNUP', 'name': name, 'email': email, 'mobile': mobile, 'campus': campus, 'major': major, 'yearIn': yearIn, 'city': city, 'mentor': mentor})
  },
  updateSignupStat (signupId, userId, stat, note) {
    return this.execute('post', '/', {'cmd': 'UPDATE_SIGNUP_STAT', 'idToken': localStorage.getItem('idToken'), 'signupId': signupId, 'userId': userId, 'stat': stat, 'note': note})
  },
  approveSignup (signupId, userId, data) {
    return this.execute('post', '/', {'cmd': 'APPROVE_SIGNUP', 'idToken': localStorage.getItem('idToken'), 'signupId': signupId, 'userId': userId, 'data': data})
  },
  updateUser (userId, data) {
    return this.execute('post', '/', {'cmd': 'UPDATE_USER', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'data': data})
  },

  createBahanPA (userId, data) {
    return this.execute('post', '/', {'cmd': 'CREATE_BAHAN_PA', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'data': data})
  },
  updateBahanPA (userId, data) {
    return this.execute('post', '/', {'cmd': 'UPDATE_BAHAN_PA', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'data': data})
  },
  deleteBahanPA (userId, bahanPaId) {
    return this.execute('post', '/', {'cmd': 'DELETE_BAHAN_PA', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'bahanPaId': bahanPaId})
  },
  updateDateBahanPA (userId, role, bahanPaId) {
    return this.execute('post', '/', {'cmd': 'UPDATE_DATE_BAHAN_PA', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'role': role, 'bahanPaId': bahanPaId})
  },

  createJournalPA (userId, data) {
    return this.execute('post', '/', {'cmd': 'CREATE_JOURNAL_PA', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'data': data})
  },
  updateJournalPA (userId, data) {
    return this.execute('post', '/', {'cmd': 'UPDATE_JOURNAL_PA', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'data': data})
  },
  deleteJournalPA (userId, journalPaId) {
    return this.execute('post', '/', {'cmd': 'DELETE_JOURNAL_PA', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'journalPaId': journalPaId})
  },

  createBahanPsk (userId, data) {
    return this.execute('post', '/', {'cmd': 'CREATE_BAHAN_PSK', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'data': data})
  },
  updateBahanPsk (userId, data) {
    return this.execute('post', '/', {'cmd': 'UPDATE_BAHAN_PSK', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'data': data})
  },
  deleteBahanPsk (userId, bahanPskId) {
    return this.execute('post', '/', {'cmd': 'DELETE_BAHAN_PSK', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'bahanPskId': bahanPskId})
  },

  createPeriod (userId, name, from, to) {
    return this.execute('post', '/', {'cmd': 'CREATE_PERIOD', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'name': name, 'from': from, 'to': to})
  },
  updatePeriod (userId, periodId, name, from, to) {
    return this.execute('post', '/', {'cmd': 'UPDATE_PERIOD', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'periodId': periodId, 'name': name, 'from': from, 'to': to})
  },
  deletePeriod (userId, periodId) {
    return this.execute('post', '/', {'cmd': 'DELETE_PERIOD', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'periodId': periodId})
  },

  createLink (userId, name, url, access) {
    return this.execute('post', '/', {'cmd': 'CREATE_LINK', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'name': name, 'url': url, 'access': access})
  },
  updateLink (userId, linkId, name, url, access) {
    return this.execute('post', '/', {'cmd': 'UPDATE_LINK', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'linkId': linkId, 'name': name, 'url': url, 'access': access})
  },
  deleteLink (userId, linkId) {
    return this.execute('post', '/', {'cmd': 'DELETE_LINK', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'linkId': linkId})
  },

  createCity (userId, city) {
    return this.execute('post', '/', {'cmd': 'CREATE_CITY', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'city': city})
  },
  updateCity (userId, cityId, city) {
    return this.execute('post', '/', {'cmd': 'UPDATE_CITY', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'cityId': cityId, 'city': city})
  },
  deleteCity (userId, cityId) {
    return this.execute('post', '/', {'cmd': 'DELETE_CITY', 'idToken': localStorage.getItem('idToken'), 'userId': userId, 'cityId': cityId})
  },

  getSignedURL (idToken, filename, filetype) {
    return this.execute('post', '/', {'cmd': 'GET_SIGNED_URL', 'idToken': idToken, 'filename': filename, 'filetype': filetype})
  },
}
